@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
  background-color: #fff;
}
.styles_Modal__3oVvi {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 20px;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .styles_Modal__3oVvi {
        width: 500px;
        left: calc(50% - 250px);
    }
}

.styles_Modal__3oVvi p {
    text-align: center;
}

.styles_CloseButton__1KEKz {
    text-align: center;
    font-size: 30px;
    border: none;
    background-color: rgba(0,0,0,0);
    color: #000;
    position: fixed;
    right: 0;
    top: -10px;
    padding: 0;
    cursor: pointer;
}
.styles_Backdrop__2RaO3 {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.styles_loader__2264n {
  color: #ffffff;
  font-size: 20px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: styles_load4__rf10N 1.3s infinite linear;
  animation: styles_load4__rf10N 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes styles_load4__rf10N {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes styles_load4__rf10N {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

  .styles_Game__1n_xT {
    padding: 10px;
    height: 100vh;
    text-align: center;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .styles_Square__2ghgP {
      border: 1px solid black;
      box-sizing: border-box;
      margin: 0 auto 2px;
      padding: 2px;
      width: 50px;
      height: 50px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
  }

  .styles_Square__2ghgP img {
    max-width:100%;
    max-height:100%;
  }

  .styles_MapSize__3qfxQ {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 20px;
  }

  .styles_Btn__16L8t{
    border-radius: 7px;
    cursor: pointer;
    outline: none;
  }

  .styles_Btn__16L8t:hover{
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
  }

  .styles_Btn__16L8t:disabled {
    cursor: not-allowed;
  }

  .styles_Btn__16L8t:disabled:hover{
    -webkit-transform: scale(1, 1) !important;
            transform: scale(1, 1) !important;
  }

  .styles_Pits__ftGZX{
    width: 220px;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  
  .styles_Map__2RWoW {
      display: inline-block;
      margin: 50px auto;
  }
  
  .styles_BtnDiv__3Nkbc button{
    background-color: #ccc;
    border: .5px solid black;
    border-radius: 5px;
    width: 45px;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    margin: 2px 2px;
    cursor: pointer;
    outline: none;
  }
  
  .styles_BtnDiv__3Nkbc button:hover{
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
  }
  
  .styles_BtnDiv__3Nkbc button:disabled{
    cursor: not-allowed !important;
  }
  
  .styles_BtnDiv__3Nkbc button:disabled:hover{
    -webkit-transform: scale(1, 1) !important;
            transform: scale(1, 1) !important;
  }

  .styles_TopIcons__2WT-W{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .styles_BottomIcons__2r71x{
    display: flex;
    flex-direction: row;
  }

  .styles_ActiveArrow__1zIY1{
    background-color: #5af500 !important;
  }

  .styles_UsedArrow__3OyHZ{
    background-color: #e70a0a !important;
  }
  
  .styles_ArrowW__voOyx{
    z-index: 2000;
    position: absolute;
    -webkit-animation-name: styles_arrowW__3nTna;
    -webkit-animation-duration: 3s;
    animation-name: styles_arrowW__3nTna;
    animation-duration: 3s;
  }

  @-webkit-keyframes styles_arrowW__3nTna{
    from{
      -webkit-transform: translate(0px, 0px) rotate(270deg);
              transform: translate(0px, 0px) rotate(270deg);
    }
    to{
      -webkit-transform: translate(0px, -1000px) rotate(270deg);
              transform: translate(0px, -1000px) rotate(270deg);
    }
  }

  @keyframes styles_arrowW__3nTna{
    from{
      -webkit-transform: translate(0px, 0px) rotate(270deg);
              transform: translate(0px, 0px) rotate(270deg);
    }
    to{
      -webkit-transform: translate(0px, -1000px) rotate(270deg);
              transform: translate(0px, -1000px) rotate(270deg);
    }
  }

  .styles_ArrowS__1D6Ec{
    z-index: 2000;
    position: absolute;
    -webkit-animation-name: styles_arrowS__1jXk6;
    -webkit-animation-duration: 3s;
    animation-name: styles_arrowS__1jXk6;
    animation-duration: 3s;
  }

  @-webkit-keyframes styles_arrowS__1jXk6{
    from{
      -webkit-transform: translate(0px, 0px) rotate(90deg);
              transform: translate(0px, 0px) rotate(90deg);
    }
    to{
      -webkit-transform: translate(0px, 1000px) rotate(90deg);
              transform: translate(0px, 1000px) rotate(90deg);
    }
  }

  @keyframes styles_arrowS__1jXk6{
    from{
      -webkit-transform: translate(0px, 0px) rotate(90deg);
              transform: translate(0px, 0px) rotate(90deg);
    }
    to{
      -webkit-transform: translate(0px, 1000px) rotate(90deg);
              transform: translate(0px, 1000px) rotate(90deg);
    }
  }

  .styles_ArrowA__230Wz{
    z-index: 2000;
    position: absolute;
    -webkit-animation-name: styles_arrowA__2goVy;
    -webkit-animation-duration: 3s;
    animation-name: styles_arrowA__2goVy;
    animation-duration: 3s;
  }

  @-webkit-keyframes styles_arrowA__2goVy{
    from{
      -webkit-transform: translate(0px, 0px) rotate(180deg);
              transform: translate(0px, 0px) rotate(180deg);
    }
    to{
      -webkit-transform: translate(-1000px, 0px) rotate(180deg);
              transform: translate(-1000px, 0px) rotate(180deg);
    }
  }

  @keyframes styles_arrowA__2goVy{
    from{
      -webkit-transform: translate(0px, 0px) rotate(180deg);
              transform: translate(0px, 0px) rotate(180deg);
    }
    to{
      -webkit-transform: translate(-1000px, 0px) rotate(180deg);
              transform: translate(-1000px, 0px) rotate(180deg);
    }
  }

  .styles_ArrowD__2xt-o{
    z-index: 2000;
    position: absolute;
    -webkit-animation-name: styles_arrowD__11iqX;
    -webkit-animation-duration: 3s;
    animation-name: styles_arrowD__11iqX;
    animation-duration: 3s;
  }

  @-webkit-keyframes styles_arrowD__11iqX{
    from{
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
    }
    to{
      -webkit-transform: translate(1000px, 0px);
              transform: translate(1000px, 0px);
    }
  }

  @keyframes styles_arrowD__11iqX{
    from{
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
    }
    to{
      -webkit-transform: translate(1000px, 0px);
              transform: translate(1000px, 0px);
    }
  }
.styles_MainDiv__1VLxt{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.styles_MainDiv__1VLxt table, td, tr{
    padding: 5px;
    border: 1px solid #ccc;
    text-align: center;
    border-collapse: collapse;
}

.styles_MainDiv__1VLxt thead{
    background-color: #ffef0a;
    font-weight: bold;
    font-size: 17px;
}

.styles_MainDiv__1VLxt thead td{
    height: 50px;
}

.styles_MainDiv__1VLxt td{
    width: 200px;
}

.styles_MainDiv__1VLxt tbody tr:nth-child(odd){
    background-color: #f2f2f2;
}
.styles_MainDiv__1hka9{
    min-width: 300px;
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.styles_BtnDiv__3DDIV{
    width: 20%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.styles_BtnSubDiv__3ssbW{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
}

.styles_Btn__1BA8F{
    border: .5px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    min-width: 100px;
    width: 100%;
    height: 50px;
    font-weight: bold;
    font-size: 20px;
    color: #000;
    outline: none;
}

.styles_BtnDiv__3DDIV button:hover{
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
}

.styles_divJogar__33s7Y{
    width: 48%;
}

.styles_divRanking__143Yf{
    width: 48%;
}

.styles_Jogar__h4IXf{
    background-color: #1eff00;
}

.styles_Ranking__Cf_Cx{
    background-color: #ffef0a;
}

.styles_HowToPlay__3EtY0{
    background-color: #00ffdd;
}
.styles_MainDiv__13ITq{
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.styles_MainDiv__13ITq p{
  text-align: justify;
  align-self: flex-start;
}

.styles_MainDiv__13ITq a{
  text-decoration: none;
  color: #008cff
}

.styles_MainDiv__13ITq a:hover{
  text-decoration: underline;
}

@media (min-width: 1200px){
  .styles_MainDiv__13ITq{
    width: 40%;
  }
}
